var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-10",attrs:{"headers":_vm.headers,"items":_vm.users,"search":_vm.search,"sort-by":"email","dense":""},scopedSlots:_vm._u([{key:"item.clients",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.clients.map(function (e) { return e.clientDesc; }).toString()))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Usuarios")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-5",attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-dialog',{attrs:{"max-width":"1200px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.createArrays()}}},'v-btn',attrs,false),on),[_vm._v("Nuevo Usuario")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Nuevo Usuario")])]),_c('v-card-text',{staticClass:"text-center"},[(_vm.showLoading == true)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"70","width":"7"}}):_vm._e(),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"email"},model:{value:(_vm.editedItem.email),callback:function ($$v) {_vm.$set(_vm.editedItem, "email", $$v)},expression:"editedItem.email"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-data-table',{staticClass:"elevation-5",attrs:{"headers":_vm.headersClients,"items":_vm.clientsAllow,"search":_vm.searchAllow,"sort-by":"clientId","dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Permitidos")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-5",attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.searchAllow),callback:function ($$v) {_vm.searchAllow=$$v},expression:"searchAllow"}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.removeAllow(item)}}},[_vm._v("mdi-minus-thick")])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-data-table',{staticClass:"elevation-5",attrs:{"headers":_vm.headersClients,"items":_vm.clientsDeny,"search":_vm.searchDeny,"sort-by":"clientId","dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Denegados")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-5",attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.searchDeny),callback:function ($$v) {_vm.searchDeny=$$v},expression:"searchDeny"}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":"green"},on:{"click":function($event){return _vm.addAllow(item)}}},[_vm._v("mdi-plus-thick")])]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"disabled":_vm.editedItem.email == '' || _vm.clientsAllow.length < 1,"color":"primary"},on:{"click":_vm.saveUser}},[_vm._v("Guardar")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6 justify-center"},[_vm._v("¿Quieres eliminar el usuario"),_c('br'),_vm._v("\""+_vm._s(_vm.editedItem.email)+"\"?")]),_c('v-card-text',{staticClass:"text-center"},[(_vm.showLoading == true)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"70","width":"7"}}):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.closeDelete}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }