<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="users"
            :search="search"
            sort-by="email"
            class="elevation-10"
            dense
        >

          <template v-slot:[`item.clients`]="{ item }">    
            <span>{{item.clients.map(e => e.clientDesc).toString()}}</span>   
          </template>


          <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Usuarios</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-text-field class="mr-5"
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>

                <v-dialog
                  v-model="dialog"
                  max-width="1200px"
                  persistent
                >
                  <template v-slot:activator="{ on, attrs }">
                    
                    <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="createArrays()"
                    >Nuevo Usuario</v-btn>
                  </template>
          
                  <v-card>
                    <v-card-title>
                    <span class="text-h5">Nuevo Usuario</span>
                    </v-card-title>

                    <v-card-text class="text-center">
                      <v-progress-circular
                        v-if="showLoading == true"
                        indeterminate
                        color="primary"
                        size="70"
                        width="7"  
                      ></v-progress-circular>
                      <v-container>
                        <v-row>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.email"
                              label="email"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <!--TABLA ALLOW-->
                          <v-col cols="6">
                            <v-data-table
                              :headers="headersClients"
                              :items="clientsAllow"
                              :search="searchAllow"
                              sort-by="clientId"
                              class="elevation-5"
                              dense
                            >

                              <template v-slot:top>
                                <v-toolbar flat>
                                  <v-toolbar-title>Permitidos</v-toolbar-title>
                                  <v-divider
                                    class="mx-4"
                                    inset
                                    vertical
                                  ></v-divider>
                                  <v-spacer></v-spacer>
                                  <v-text-field class="mr-5"
                                    v-model="searchAllow"
                                    append-icon="mdi-magnify"
                                    label="Buscar"
                                    single-line
                                    hide-details
                                  ></v-text-field>
                                </v-toolbar>
                              </template>

                              <template v-slot:[`item.actions`]="{ item }">
                              <v-icon
                                small
                                @click="removeAllow(item)"
                                color="red"
                              >mdi-minus-thick</v-icon>
                            </template>

                            </v-data-table>
                          </v-col>


                          <!--TABLA DENY-->
                          <v-col cols="6">
                            <v-data-table
                              :headers="headersClients"
                              :items="clientsDeny"
                              :search="searchDeny"
                              sort-by="clientId"
                              class="elevation-5"
                              dense
                            >

                            <template v-slot:top>
                              <v-toolbar flat>
                                <v-toolbar-title>Denegados</v-toolbar-title>
                                <v-divider
                                  class="mx-4"
                                  inset
                                  vertical
                                ></v-divider>
                                <v-spacer></v-spacer>
                                <v-text-field class="mr-5"
                                  v-model="searchDeny"
                                  append-icon="mdi-magnify"
                                  label="Buscar"
                                  single-line
                                  hide-details
                                ></v-text-field>
                              </v-toolbar>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                              <v-icon
                                small
                                @click="addAllow(item)"
                                color="green"
                              >mdi-plus-thick</v-icon>
                            </template>

                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                        <v-btn color="primary" @click="close">Cancelar</v-btn>
                        <v-btn :disabled="editedItem.email == '' || clientsAllow.length < 1" color="primary" @click="saveUser">Guardar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>


                

                <v-dialog v-model="dialogDelete" max-width="500px" persistent>
                  <v-card>
                    <v-card-title class="text-h6 justify-center">¿Quieres eliminar el usuario<br>"{{editedItem.email}}"?</v-card-title>
                    <v-card-text class="text-center">
                      <v-progress-circular
                        v-if="showLoading == true"
                        indeterminate
                        color="primary"
                        size="70"
                        width="7"  
                      ></v-progress-circular>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="closeDelete">Cancelar</v-btn>
                      <v-btn color="primary" @click="deleteItemConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
          </template>


          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              @click="editItem(item)"
              color="blue"
            >mdi-pencil</v-icon>
            <v-icon
              small
              @click="deleteItem(item)"
              color="red"
            >mdi-delete</v-icon>
          </template>
        </v-data-table>


      </v-col>
    </v-row>
  </v-container>
  
</template>

<script>
import {db} from '../firebase'
import { collection, query, where, onSnapshot, doc, setDoc, deleteDoc } from "firebase/firestore";
import { mapState } from 'vuex';

export default {
  data() {
    return {
      showLoading: false,
      clientsAllow: [],
      clientsDeny: [],
      users: [],
      search: '',
      searchAllow: '',
      searchDeny: '',
      headers: [
        { text: 'Usuario', align: 'start', value: 'email'},
        { text: 'Role', value: 'role'},
        { text: 'Clientes', value: 'clients'},
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      headersClients: [
        { text: 'ID cliente', align: 'start', value: 'clientId'},
        { text: 'Descripción', value: 'clientDesc'},
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        email: '',
        role: '',
        clients: []
      },
      defaultItem: {
        email: '',
        role: '',
        clients: []
      }
    }
  },

  computed: {
    ...mapState(['clients'])
  },

  methods: {
    close () {
      this.dialog = false
      this.searchAllow = ''
      this.searchDeny = ''
      this.clientsAllow = []
      this.clientsDeny = []
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    editItem (item) {
      this.editedIndex = this.clients.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.clientsAllow = this.editedItem.clients
      //this.clientsDeny = this.clients.filter(e => e.clientId != 'A100')
      this.clientsDeny = this.clients.filter(el => !this.clientsAllow.some(el2 => el2.clientId == el.clientId))
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async deleteItemConfirm () {
      try {
        this.showLoading = true
        await deleteDoc(doc(db, "users", this.editedItem.email))
        this.closeDelete()
        this.showLoading = false
      } catch (error) {
        console.log(error)
      } 
    },

    async saveUser(){
      try {
        this.showLoading = true
        this.editedItem.clients = this.clientsAllow
        //FIRESTORE
        await setDoc(doc(db, "users", this.editedItem.email),{
          email: this.editedItem.email,
          role: "user",
          clients: this.editedItem.clients 
        })
        this.close()
        this.showLoading = false
      } catch (error) {
        console.log(error)
      }
    },

    getUsers(){
      const q = query(collection(db, "users"), where("role", "!=", "anuadmin"))
      const unsubscribe = onSnapshot(q, querySnapshot =>{
        this.users = []
        querySnapshot.forEach(item => {
          this.users.push(item.data())
        })
      })
    },

    createArrays(){
      this.clientsDeny = this.clients
    },

    addAllow(client){
      this.clientsAllow.push(client)
      this.clientsDeny = this.clientsDeny.filter(item => item != client)
    },

    removeAllow(client){
      this.clientsDeny.push(client)
      this.clientsAllow = this.clientsAllow.filter(item => item != client)
    },

  },

  created() {
    this.getUsers()
  },

}
</script>

